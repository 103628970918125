.outter_container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #f9f6fc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .form_holder {
    position: relative;
    & #form__submnt {
      position: relative;
      display: flex;
      min-width: 300px;
      width: 100%;
      max-width: 600px;
    }
  }

  h1 {
    font-size: 32px;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.fancy-button {
  background-color: purple;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  margin-left: 10px;
}

.fancy-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.fancy-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.fancy-input {
  background-color: white;
  border: 2px solid purple;
  border-radius: 5px;
  font-size: 18px;
  padding: 10px;
  max-width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.fancy-input:focus {
  border-color: blue;
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) inset;
}

/* The modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal content */
.modal-content {
  background-color: #fefefe;
  position: absolute; /* Position the modal in the center of the page */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Offset the origin */
  padding: 20px;
  border: 1px solid #888;
  width: 100%; /*d be more or less, depending on screen size */
  max-width: 567px;
  border-radius: 10px;
  position: relative;
}

/* The close button */
.close {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 10px;
}

.fancy-link {
  color: purple;
  font-size: 18px;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
  text-decoration: underline;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
